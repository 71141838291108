import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'
import { EloMinusCircleIcon, EloAddCircleIcon, EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import '../elo-input.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: number
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  dataTestId?: string
  inputSize?: 'large' | 'xlarge'
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  min?: number
  max?: number
  tooltipOptions?: TooltipProps
  value?: number
  step?: number
}

export const EloInputNumber: React.FC<Props> = ({
  id,
  name,
  placeholder = '',
  defaultValue = 0,
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  dataTestId = TEST_IDS.input,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  required,
  min,
  max,
  tooltipOptions,
  value,
  step,
  inputSize = 'xlarge',
}) => {
  const [currentValue, setCurrentValue] = useState<number | string>(defaultValue)
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let value: string | number
    if (event.target.value === '') {
      value = event.target.value
    } else {
      value = Number(event.target.value)
      if (event.target.value !== '') {
        if (value <= min) {
          value = min
        } else if (value >= max) {
          value = max
        }
      }
    }

    setCurrentValue(value)

    onChange({
      ...event,
      target: {
        ...event.target,
        //@ts-ignore
        value,
      },
    })
  }, [])

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setCurrentValue(defaultValue || min || 0)
      if (onBlur) {
        onBlur({
          ...event,
          target: {
            ...event.target,
            //@ts-ignore
            value: defaultValue || min || 0,
          },
        })
      }
    }
  }

  const inputClasses = classNames(className, 'elo-input', `elo-input--number`, `elo-input--${inputSize}`, {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
  })

  const triggerChange = () => {
    const event = new Event('change', {
      bubbles: true,
      cancelable: true,
    })
    inputRef.current.dispatchEvent(event)
  }

  const handleIncrement = () => {
    inputRef.current.stepUp()
    triggerChange()
  }

  const handleDecrement = () => {
    inputRef.current.stepDown()
    triggerChange()
  }

  return (
    <div className={inputClasses}>
      <div className='elo-input__label'>
        {label && (
          <label htmlFor={id} className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </label>
        )}
        <div className='elo-input__number'>
          <button
            type='button'
            onClick={handleDecrement}
            className='elo-input__number-button'
            {...((disabled || Number(currentValue) <= min) && { disabled: true })}
          >
            <EloMinusCircleIcon />
          </button>
          <input
            ref={inputRef}
            type='number'
            value={currentValue}
            aria-disabled={disabled}
            data-testid={dataTestId}
            tabIndex={disabled ? -1 : 0}
            readOnly={disabled}
            max={max}
            min={min}
            step={step}
            onChange={handleChange}
            onBlur={handleBlur}
            {...{
              id,
              name,
              placeholder,
              onFocus,
              required,
            }}
          />
          <button
            type='button'
            className='elo-input__number-button'
            onClick={handleIncrement}
            {...((disabled || Number(currentValue) >= max) && { disabled: true })}
          >
            <EloAddCircleIcon />
          </button>
        </div>
      </div>
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
