import React from 'react'
import { EloIconProps } from '../types'

export const EloSecurePurchaseIcon: React.FC<EloIconProps> = ({ width = 158, height = 40 }) => (
  <svg width={width} height={height} viewBox='0 0 87 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_9728_60338)'>
      <path
        d='M12.1242 24C18.8202 24 24.2484 18.6274 24.2484 12C24.2484 5.37258 18.8202 0 12.1242 0C5.42819 0 0 5.37258 0 12C0 18.6274 5.42819 24 12.1242 24Z'
        fill='#2BFF99'
      />
      <path
        d='M16.5242 17.4658C16.135 17.851 15.5971 18.0893 15.003 18.089C14.6502 18.089 14.3176 18.005 14.0236 17.8559C13.6214 17.6519 13.2926 17.3266 13.0865 16.9285C12.9367 16.6376 12.8518 16.3088 12.8518 15.9599V15.9043C12.8568 15.7038 12.89 15.51 12.9474 15.3277C12.9474 15.3269 12.9478 15.3261 12.9474 15.3258C13.0506 14.9947 13.106 14.6428 13.1064 14.2781C13.1064 13.8127 13.0158 13.3681 12.8514 12.9602C12.6691 12.507 12.3949 12.0998 12.0524 11.7604C11.7099 11.4211 11.2981 11.1498 10.8401 10.9697C10.428 10.807 9.97878 10.7177 9.50854 10.7173C9.14 10.7173 8.78445 10.7725 8.44994 10.8747C8.44994 10.8747 8.44879 10.8747 8.44803 10.8747C8.26375 10.9315 8.0684 10.9644 7.86539 10.9693H7.80919C7.45709 10.9693 7.12448 10.8857 6.83049 10.737C6.4283 10.533 6.09952 10.2076 5.89346 9.8096C5.74283 9.51864 5.65796 9.18984 5.65796 8.84023C5.65796 8.25226 5.89843 7.7199 6.28762 7.33473C6.6768 6.94955 7.21509 6.71118 7.80919 6.71118H17.077C17.1191 6.71118 17.1535 6.74523 17.1535 6.78685V15.9592C17.1535 16.5471 16.913 17.0795 16.5235 17.465L16.5242 17.4658Z'
        fill='#000004'
      />
      <path
        d='M9.60183 16.3279C10.7922 16.3279 11.7572 15.3728 11.7572 14.1946C11.7572 13.0164 10.7922 12.0613 9.60183 12.0613C8.41142 12.0613 7.44641 13.0164 7.44641 14.1946C7.44641 15.3728 8.41142 16.3279 9.60183 16.3279Z'
        fill='#000004'
      />
    </g>
    <path
      d='M30.9523 13.1926C29.8704 13.1926 28.9303 13.7982 28.9303 14.7877C28.9303 16.9145 32.2729 15.7994 32.2729 17.2689C32.2729 17.8228 31.7431 18.1699 31.0045 18.1699C30.0868 18.1699 29.5645 17.616 29.5645 16.7816L28.8035 17.1434C28.9378 18.2142 29.8107 18.8566 31.0045 18.8566C32.1982 18.8566 33.0936 18.2585 33.0936 17.2246C33.0936 15.0536 29.7436 16.1096 29.7436 14.736C29.7436 14.1969 30.2509 13.8794 30.9523 13.8794C31.7804 13.8794 32.2803 14.3668 32.2803 15.0462L33.019 14.736C32.9369 13.8942 32.1609 13.1926 30.9523 13.1926Z'
      fill='#454B50'
    />
    <path
      d='M35.5251 14.7803C34.406 14.7803 33.5927 15.6517 33.5927 16.8332C33.5927 18.0222 34.3687 18.8566 35.5773 18.8566C36.2861 18.8566 36.9651 18.5834 37.3307 17.9779L36.7562 17.4831C36.5398 17.9705 36.1668 18.1994 35.5923 18.1994C34.973 18.1994 34.488 17.8376 34.406 17.0696H37.368C37.3829 16.9514 37.3829 16.8554 37.3829 16.7594C37.3829 15.5852 36.6741 14.7803 35.5251 14.7803ZM34.4134 16.4936C34.5179 15.7846 34.9655 15.4376 35.5102 15.4376C36.1518 15.4376 36.5473 15.8511 36.5473 16.4936H34.4134Z'
      fill='#454B50'
    />
    <path
      d='M39.9402 14.7803C38.7464 14.7803 37.8735 15.6222 37.8735 16.8185C37.8735 18.0369 38.7166 18.8566 39.9402 18.8566C40.7535 18.8566 41.4399 18.4135 41.701 17.808L41.0295 17.4092C40.9101 17.8302 40.4625 18.1477 39.9402 18.1477C39.1642 18.1477 38.6718 17.6382 38.6718 16.8185C38.6718 15.9988 39.1866 15.4819 39.9402 15.4819C40.4401 15.4819 40.8952 15.7551 41.022 16.2351L41.701 15.8289C41.4548 15.2234 40.7609 14.7803 39.9402 14.7803Z'
      fill='#454B50'
    />
    <path
      d='M45.6618 14.9059H44.8859V17.0031C44.8859 17.7416 44.5128 18.192 43.8563 18.192C43.2967 18.192 43.0132 17.8819 43.0132 17.2542V14.9059H42.2372V17.3576C42.2372 18.2954 42.7819 18.8566 43.6847 18.8566C44.1995 18.8566 44.6173 18.6794 44.8859 18.2437V18.7459H45.6618V14.9059Z'
      fill='#454B50'
    />
    <path
      d='M46.5553 14.9059V18.7459H47.3312V16.8849C47.3312 15.9692 47.7789 15.5779 48.4131 15.5779C48.5549 15.5779 48.6742 15.6 48.8085 15.6369V14.8616C48.6966 14.832 48.622 14.8246 48.5026 14.8246C48.1221 14.8246 47.6222 14.9723 47.3312 15.5409V14.9059H46.5553Z'
      fill='#454B50'
    />
    <path
      d='M51.047 14.7803C49.9279 14.7803 49.1146 15.6517 49.1146 16.8332C49.1146 18.0222 49.8906 18.8566 51.0992 18.8566C51.808 18.8566 52.487 18.5834 52.8526 17.9779L52.2781 17.4831C52.0617 17.9705 51.6887 18.1994 51.1142 18.1994C50.4949 18.1994 50.0099 17.8376 49.9279 17.0696H52.8899C52.9048 16.9514 52.9048 16.8554 52.9048 16.7594C52.9048 15.5852 52.196 14.7803 51.047 14.7803ZM49.9353 16.4936C50.0398 15.7846 50.4874 15.4376 51.0321 15.4376C51.6737 15.4376 52.0692 15.8511 52.0692 16.4936H49.9353Z'
      fill='#454B50'
    />
    <path
      d='M55.6079 18.7459H56.4137V16.5748H57.9059C58.8833 16.5748 59.6891 15.9988 59.6891 14.9502C59.6891 13.9606 58.9728 13.3182 57.8686 13.3182H55.6079V18.7459ZM56.4137 15.8585V14.0345H57.8835C58.5177 14.0345 58.8833 14.4185 58.8833 14.9502C58.8833 15.4966 58.4878 15.8585 57.8686 15.8585H56.4137Z'
      fill='#454B50'
    />
    <path
      d='M63.6354 14.9059H62.8594V17.0031C62.8594 17.7416 62.4864 18.192 61.8298 18.192C61.2702 18.192 60.9867 17.8819 60.9867 17.2542V14.9059H60.2108V17.3576C60.2108 18.2954 60.7554 18.8566 61.6582 18.8566C62.173 18.8566 62.5908 18.6794 62.8594 18.2437V18.7459H63.6354V14.9059Z'
      fill='#454B50'
    />
    <path
      d='M64.3796 14.9059V18.7459H65.1556V16.8849C65.1556 15.9692 65.6032 15.5779 66.2374 15.5779C66.3792 15.5779 66.4986 15.6 66.6329 15.6369V14.8616C66.521 14.832 66.4463 14.8246 66.327 14.8246C65.9465 14.8246 65.4466 14.9723 65.1556 15.5409V14.9059H64.3796Z'
      fill='#454B50'
    />
    <path
      d='M68.8564 14.7803C67.6627 14.7803 66.7897 15.6222 66.7897 16.8185C66.7897 18.0369 67.6328 18.8566 68.8564 18.8566C69.6697 18.8566 70.3561 18.4135 70.6172 17.808L69.9458 17.4092C69.8264 17.8302 69.3787 18.1477 68.8564 18.1477C68.0805 18.1477 67.5881 17.6382 67.5881 16.8185C67.5881 15.9988 68.1029 15.4819 68.8564 15.4819C69.3563 15.4819 69.8115 15.7551 69.9383 16.2351L70.6172 15.8289C70.371 15.2234 69.6772 14.7803 68.8564 14.7803Z'
      fill='#454B50'
    />
    <path
      d='M73.3321 14.7803C72.7128 14.7803 72.3174 15.0388 72.0786 15.4302V13.3182H71.3027V18.7459H72.0786V16.6856C72.0786 15.9176 72.4815 15.4819 73.1306 15.4819C73.735 15.4819 74.026 15.8216 74.026 16.5231V18.7459H74.8019V16.4123C74.8019 15.3785 74.2796 14.7803 73.3321 14.7803Z'
      fill='#454B50'
    />
    <path
      d='M75.4165 17.7489C75.4165 18.4209 75.9537 18.8566 76.7297 18.8566C77.2668 18.8566 77.7742 18.6646 78.0652 18.2585L78.2368 18.7459H78.9157L78.7889 18.0074V16.0874C78.7889 15.2382 78.1696 14.7803 77.1699 14.7803C76.282 14.7803 75.5956 15.2825 75.4911 16.0136L76.1925 16.2794C76.1925 15.7034 76.6103 15.3932 77.1699 15.3932C77.6474 15.3932 78.0279 15.5631 78.0279 15.9692C78.0279 16.3385 77.8637 16.3828 76.6923 16.5822C75.9239 16.7225 75.4165 17.0917 75.4165 17.7489ZM76.2372 17.712C76.2372 17.3428 76.5207 17.1951 76.9087 17.1139C77.6772 16.9736 77.9085 16.9366 78.0279 16.8332V17.2025C78.0279 17.8006 77.6324 18.2585 76.9013 18.2585C76.476 18.2585 76.2372 18.0222 76.2372 17.712Z'
      fill='#454B50'
    />
    <path
      d='M81.0521 14.7803C80.1941 14.7803 79.4704 15.2825 79.4704 15.9766C79.4704 17.52 81.9325 16.8628 81.9325 17.7194C81.9325 18.0739 81.5818 18.2363 81.1267 18.2363C80.5448 18.2363 80.1344 17.9557 80.1046 17.3723L79.351 17.6899C79.4778 18.3914 80.1344 18.8566 81.1267 18.8566C82.0892 18.8566 82.7682 18.4431 82.7682 17.6677C82.7682 16.1539 80.2687 16.7742 80.2687 15.9323C80.2687 15.6222 80.5597 15.3932 81.0521 15.3932C81.6117 15.3932 81.9474 15.6812 81.9847 16.1686L82.7159 15.8954C82.604 15.2234 81.9698 14.7803 81.0521 14.7803Z'
      fill='#454B50'
    />
    <path
      d='M85.1423 14.7803C84.0231 14.7803 83.2099 15.6517 83.2099 16.8332C83.2099 18.0222 83.9858 18.8566 85.1945 18.8566C85.9033 18.8566 86.5823 18.5834 86.9479 17.9779L86.3734 17.4831C86.157 17.9705 85.7839 18.1994 85.2094 18.1994C84.5902 18.1994 84.1052 17.8376 84.0231 17.0696H86.9852C87.0001 16.9514 87.0001 16.8554 87.0001 16.7594C87.0001 15.5852 86.2913 14.7803 85.1423 14.7803ZM84.0306 16.4936C84.135 15.7846 84.5827 15.4376 85.1274 15.4376C85.769 15.4376 86.1644 15.8511 86.1644 16.4936H84.0306Z'
      fill='#454B50'
    />
    <path
      d='M30.6686 6.60546C29.5495 6.60546 28.7362 7.47685 28.7362 8.65839C28.7362 9.84731 29.5122 10.6818 30.7208 10.6818C31.4296 10.6818 32.1086 10.4085 32.4742 9.803L31.8997 9.30823C31.6833 9.79562 31.3103 10.0245 30.7358 10.0245C30.1165 10.0245 29.6315 9.6627 29.5495 8.8947H32.5115C32.5264 8.77654 32.5264 8.68054 32.5264 8.58454C32.5264 7.41039 31.8176 6.60546 30.6686 6.60546ZM29.5569 8.3187C29.6614 7.60977 30.109 7.2627 30.6537 7.2627C31.2953 7.2627 31.6908 7.67623 31.6908 8.3187H29.5569Z'
      fill='#000004'
    />
    <path d='M33.2856 5.14331V10.571H34.0615V5.14331H33.2856Z' fill='#000004' />
    <path
      d='M36.7765 6.60546C35.5976 6.60546 34.6948 7.43993 34.6948 8.64362C34.6948 9.84731 35.5976 10.6818 36.7765 10.6818C37.9628 10.6818 38.873 9.84731 38.873 8.64362C38.873 7.43993 37.9628 6.60546 36.7765 6.60546ZM35.4932 8.64362C35.4932 7.83131 36.0304 7.307 36.7765 7.307C37.53 7.307 38.0672 7.83131 38.0672 8.64362C38.0672 9.45593 37.53 9.97285 36.7765 9.97285C36.0453 9.97285 35.4932 9.45593 35.4932 8.64362Z'
      fill='#000004'
    />
    <path
      d='M41.7061 10.6818C42.885 10.6818 43.6759 9.79562 43.6759 8.651C43.6759 7.499 42.885 6.60546 41.7136 6.60546C41.0869 6.60546 40.6168 6.85654 40.3035 7.24054V6.731H39.5275V12.1587H40.3035V10.0467C40.6094 10.4307 41.0645 10.6818 41.7061 10.6818ZM40.2811 8.651C40.2811 7.87562 40.8108 7.29962 41.5793 7.29962C42.3702 7.29962 42.8701 7.883 42.8701 8.651C42.8701 9.41162 42.3702 9.98023 41.5793 9.98023C40.8108 9.98023 40.2811 9.419 40.2811 8.651Z'
      fill='#000004'
    />
    <path
      d='M44.1686 9.57408C44.1686 10.2461 44.7058 10.6818 45.4817 10.6818C46.0189 10.6818 46.5263 10.4898 46.8173 10.0836L46.9889 10.571H47.6678L47.541 9.83254V7.91254C47.541 7.06331 46.9217 6.60546 45.9219 6.60546C45.0341 6.60546 44.3476 7.10762 44.2432 7.8387L44.9445 8.10454C44.9445 7.52854 45.3623 7.21839 45.9219 7.21839C46.3994 7.21839 46.7799 7.38823 46.7799 7.79439C46.7799 8.16362 46.6158 8.20793 45.4444 8.40731C44.6759 8.54762 44.1686 8.91685 44.1686 9.57408ZM44.9893 9.53716C44.9893 9.16793 45.2728 9.02023 45.6608 8.939C46.4293 8.7987 46.6606 8.76177 46.7799 8.65839V9.02762C46.7799 9.62577 46.3845 10.0836 45.6533 10.0836C45.228 10.0836 44.9893 9.84731 44.9893 9.53716Z'
      fill='#000004'
    />
    <path
      d='M51.3934 6.731V7.20362C51.0875 6.84177 50.6324 6.60546 50.0579 6.60546C48.8865 6.60546 48.1329 7.46208 48.1329 8.56239C48.1329 9.64793 48.8865 10.4972 50.0579 10.4972C50.6324 10.4972 51.0875 10.2608 51.3934 9.899V10.4233C51.3934 11.1544 50.9084 11.6122 50.125 11.6122C49.461 11.6122 49.0208 11.3021 48.8268 10.7113L48.1627 11.1544C48.4612 11.8855 49.1252 12.2695 50.1175 12.2695C51.3785 12.2695 52.1693 11.5753 52.1693 10.475V6.731H51.3934ZM48.9312 8.56239C48.9312 7.8387 49.4162 7.29962 50.1922 7.29962C50.9383 7.29962 51.4232 7.82393 51.4232 8.56239C51.4232 9.26393 50.9383 9.803 50.1922 9.803C49.4162 9.803 48.9312 9.26393 48.9312 8.56239Z'
      fill='#000004'
    />
    <path
      d='M54.7014 6.60546C53.5822 6.60546 52.769 7.47685 52.769 8.65839C52.769 9.84731 53.5449 10.6818 54.7536 10.6818C55.4624 10.6818 56.1414 10.4085 56.5069 9.803L55.9324 9.30823C55.7161 9.79562 55.343 10.0245 54.7685 10.0245C54.1493 10.0245 53.6643 9.6627 53.5822 8.8947H56.5442C56.5592 8.77654 56.5592 8.68054 56.5592 8.58454C56.5592 7.41039 55.8504 6.60546 54.7014 6.60546ZM53.5897 8.3187C53.6941 7.60977 54.1418 7.2627 54.6864 7.2627C55.3281 7.2627 55.7235 7.67623 55.7235 8.3187H53.5897Z'
      fill='#000004'
    />
    <defs>
      <clipPath id='clip0_9728_60338'>
        <rect width='24.2484' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
