import React from 'react'
import { EloInfoIcon, EloEditIcon, EloDeleteIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

type Option = {
  value: string
  label: string
}

type OptionActionsProps<T> = {
  data: T
  getTooltip?: (option: T) => string
  handleEdit?: (Option: T) => void
  handleDelete?: (Option: T) => void
}

export const OptionActions = <T extends Option>({
  data,
  getTooltip,
  handleEdit,
  handleDelete,
}: OptionActionsProps<T>) => (
  <div className='elo-select-actions'>
    {!!getTooltip && (
      <span className='elo-select-action'>
        <EloTooltip placement='left' content={getTooltip(data)}>
          <EloInfoIcon size={16} />
        </EloTooltip>
      </span>
    )}
    {handleEdit && (
      <span
        className='elo-select-action'
        onClick={(e) => {
          e.stopPropagation()
          handleEdit(data)
        }}
      >
        <EloEditIcon size={16} />
      </span>
    )}
    {handleDelete && (
      <span
        className='elo-select-action'
        onClick={(e) => {
          e.stopPropagation()
          handleDelete(data)
        }}
      >
        <EloDeleteIcon size={16} />
      </span>
    )}
  </div>
)
